/* Grid system */
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
  padding: 0 !important;
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}

.menu-active .content {
	/*padding-top: calc(9.5% + 2.15em);*/
}

/**/

/*.container-fluid {
  position: 		relative;
  padding-left:		0;
  padding-right: 	0;
}*/
	
.container {
  width: 			100%;
  /*max-width: 		1950px;
  overflow: 		hidden;*/
}

/*nav > .container,
.navbar > .container,
.content-info > .container,
.wrap > .container,
.main > .container,
.container-fluid > .container,
.fixed-inner > .container,
.cover > .container,
.staff > .container*/
.container {
  /*padding-left:		3.8888%;
  padding-right:	3.8888%;*/
  transition: 		width 300ms ease-out;
}

.wrap {
  padding-left: 5%;
}

.main .container {
  /*padding-top:		2.3809%;
  padding-bottom:	2.3809%;*/

  &:first-child,
  &:first-child .row,
  &:last-child .row {

  }

  &:last-child,
  &:last-child .row {

  }

  .home & {

  }

}

/* remove? */	
/*.fixed-inner {
  position: 		absolute;
  top: 				0;
  left: 			0;
  height: 			100%;
  width: 			100%;
  z-index: 			98;
}*/
	
.main .row {
  /*padding: 	2.635% 0;*/

  .home & {

  }

  &.page-title {

  }

}



/*.wrap {
  height: auto;
}*/
/*.content {
  padding-top: 5.75%;
}*/

.content {

}

.home .content,
.single-projects .content,
.page-id-76 .content {
  padding-top: 0;
}



/* Laptop // bootstrap large devices less than 1200px ----------- */
	@include media-breakpoint-down(lg) {
		
	
	}	
	@include media-breakpoint-up(lg) {

    /*nav > .container,
		.navbar > .container,
		.content-info > .container,
		.wrap > .container,
		.main > .container,
		.container-fluid > .container,
		.fixed-inner > .container,
		.cover > .container,
		.staff > .container*/
    .container {
      padding-left:		$container-padding;
      padding-right:	$container-padding;
    }

    .col-md-6 > .container,
    .col-lg-6 > .container,
    #navbarNav > .container {
      padding-left:		calc(#{$container-padding} * 2);
      padding-right:	calc(#{$container-padding} * 2);
    }

	}

/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-down(md) {

    .container {
      padding-left:		$container-padding;
      padding-right:	$container-padding;
    }

    .content {

    }

	
	}	

/* Tablet // bootstrap devices 768px or larger ----------- */
	@include media-breakpoint-up(md) {



	}

/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {
		


		/*.col-padding {
		  padding: 5% 0;
		}*/
		
	}
