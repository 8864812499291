/* Search form */
.search-form {
  @extend .form-inline;
  display: inline-block;
  position: relative;
  .search-label {
    background: url("../images/icon-search.png") left center no-repeat;
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
    margin: 0 0.3em -1px 2em;
    display: inline-block;
    cursor: pointer;
  }
  input {
    line-height: 1;
    padding: 0;
    margin-right: 0;
    width: 0;
    transition: all 400ms ease 250ms;
    &.visible {
      width: 23.462em;
      margin-right: 1.4em;
      transition: all 400ms;
    }
  }
  .search-close {
    padding: 0 !important;
    width: 0;
    transition: width 400ms ease 250ms;
    &.visible {
      width: 12px;
      transition: width 400ms;
    }
  }
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* forms */
::-webkit-input-placeholder {
  color: #BBB;
}
::-moz-placeholder {
  color: #BBB;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #BBB;
}
:-moz-placeholder {
  color: #BBB;
}

input {
  font-family: $font-family-light;
  font-size: 0.813em;
  border: none;
  border-bottom: 1px solid #D0D0D0;
}

@include media-breakpoint-down(md) {



}

@include media-breakpoint-up(lg) {



}