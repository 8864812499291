:focus {outline:none !important;}

.btn, 
.comment-form input[type=submit], 
.search-form .search-submit {
  border: 2px solid $text-color;
  padding: 0.55em 1.55em;
  margin: 0.485em 0.485em 0.485em 0;
  font-size: 0.9em;
  border-radius: 0;
}

.btn i {
  margin-right: 8px;
}

.btn i.icon {
  background-size: contain !important;
  width: 17px;
  height: 17px;
  margin-top: 3px;
  display: inline-block;
}

.back {
  /*position: absolute;
  top: 4%;
  left: 22%;
  z-index: 1040;*/
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  display: inline-block;
  margin-top: 4px;
  margin-left: 1.9em;
  line-height: 2em;
  span {
    border: 1px solid $brand-primary;
    border-radius: 50%;
    display: inline-block;
    height: 1.9em;
    width: 1.9em;
  }
}

.logo-green .back {
  color: $brand-primary;
  span,
  .arrow:after,
  .arrow:before {
    border-color: $brand-primary;
  }
}

.logo-white .back {
  color: #fff;
  span,
  .arrow:after,
  .arrow:before {
    border-color: #fff;
  }
}

.logo-orange .back {
  color: #CD6440;
  span,
  .arrow:after,
  .arrow:before {
    border-color: #CD6440;
  }
}

@include media-breakpoint-up(lg) {
  .back {
    font-size: 12px;
    letter-spacing: 0.7px;
    span {
      height: 2.1em;
      width: 2.1em;
      margin-right: 2px;
    }
  }
}

@include media-breakpoint-down(md) {
  .back {
    margin: 0.9em -0.1em 0 2.4em;
  }

  /*.single-projects .back {
    color: #fff !important;
    span,
    .arrow:after,
    .arrow:before {
      border-color: #fff !important;
    }
  }*/
  .single-projects.menu-active .back {
    display: none;
  }
}

.scroll {
  font-family: $font-family-sans-serif;
  text-align: center;
  display: block;
}

.content-info .scroll {
  padding: 0.95em 2em 1.95em 3.5em;
  font-size: 1.615em;
  letter-spacing: 0.5px;
}

@include media-breakpoint-up(md) {

  /*.scroll {
    bottom: -2.1em;
  }*/

}

/*prev,
.next {
  display: inline-block;
  position: relative;
  color: $light-color;
  border-width: 0 !important;
  padding: 7px 7px 3px 0;
}

.prev:hover,
.next:hover,
.prev:hover .arrow:before,
.prev:hover .arrow:after,
.next:hover .arrow:before,
.next:hover .arrow:after {
  color: $text-color;
  border-color: $text-color;
  transition: all 250ms ease-in-out;
  text-decoration: none !important;
}*/

/*.prev > span {
  padding-left: 116px;
}*/

.angle {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-left: 1px solid $text-color;
  border-bottom: 1px solid $text-color;
  &.left {
    transform: rotate(45deg);
  }
}

.arrow {
  -webkit-backface-visibility: hidden;
  padding: 0 5px;
  display: inline-block;
  position: relative;
  margin: 0 0 5px 1px;
			
  &:before, 
  &:after {
  	content: "";
  	position: absolute;
  	display: block;
    transition: all 300ms ease-out;
  }
			
  &:before {	  
  	right: 4px;
  	top: -3px;
  	width: 15px;
  	height: 1px;
  	transform: rotate(-45deg);
  	transform-origin: right top;
  	box-shadow: inset 0 0 0 10px;
  }
  .back &:before {
    width: 8px;
  }
			
  &:after {
  	width: 8px;
  	height: 8px;
  	border-width: 1px 1px 0 0;
  	border-style: solid;
  	border-color: $brand-primary;
  	top: -3px;
  	right: 3px;
  }
  .back &:after {
    width: 5px;
    height: 5px;
  }

  &.right {
    transform: rotate(45deg);
    &.up{
      transform: none;
    }
    &.down{
      transform: rotate(90deg);
    }
  }
  &.left {
    margin: 0 18px 5px 0;
    transform: rotate(-135deg);
    &.up{
      transform: rotate(-90deg);
    }
    &.down{
      transform: rotate(180deg);
    }
  }
  &.up{
    transform: rotate(-45deg);
  }
  &.down{
    transform: rotate(135deg);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@include media-breakpoint-up(lg) {
  .arrow {
    padding: 0 0 0.5em 0.5em;
        
    &:before {    
      right: 1px;
      top: 2px;
      width: 12px;
      height: 1px;
    }
    .back &:before {
      width: 10px;
    }
        
    &:after {
      width: 6px;
      height: 6px;
      border-width: 1px 1px 0 0;
      top: 2px;
      right: 0;
    }

    &.left {
      margin: 0 1.5em 0.3em 0;
    }

    &.right {
      margin: 0 0 -0.1em 1.5em;
    }
  }
}

/*** CLOSE ***/
.search-close {
  @include text-indent;
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  top: 6px;
  right: 0;
  opacity: 1;
  &::before, 
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    right: 0;
    margin-top: -1px;
    background: $text-color;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

@include media-breakpoint-up(lg) {
  /*
  .close {
    opacity: 1;
    width: 28px;
    height: 28px;
    top: -2px;
    right: 21px;
    cursor: pointer;
    transform: rotate(0);
    -webkit-backface-visibility: hidden;
    border: none;
    &:hover {
      opacity: 1;
      transform: rotate(90deg);
      transition: all 300ms ease-in-out;
    }
    &::before, 
    &::after {
      height: 3px;
    }
  }
  */
}

/*** Filter Toggles ***/
/*
.toggle-btn {
  display: inline-block;
  transition: all 200ms;
}
@include media-breakpoint-up(md) {
  .toggle-btn {
    color: $dark-color !important;
    padding: 5px 10px;
    font-size: 1.1em;
  }
  .toggle-btn.active {
    transform: rotate(45deg);
  }
  .project-toggler.up {
    font-size: 0.6em;
    padding: 1.5em 1em 1em 1em;
    margin: 0 0 -2.3em -1em;
    display: block;
  }
}
@include media-breakpoint-down(sm) {
  .toggle-btn {
    color: $light-color !important;
  }
  .toggle-btn:after {
    content: " +";
    font-size: 1.15em;
    line-height: 1.4em;
    display: inline-block;
  }
  .toggle-btn.active:after {
    content: " -";
  }
}
*/

@media screen and (max-width: 320px) {
  .back {
    margin-left: 1em;
    font-size: 10px;
    /*letter-spacing: 0;*/
    .arrow {
      margin: 0 0 4px 12px;
    }
  }
}

@media (hover: hover) {

  .btn:hover {
    border: 2px solid $text-color;
    background-color: #f2f2f2;
    text-decoration: none;
    transition: all 300ms;
  }

  /*.posts-navigation a:hover {
    opacity: 0.8;
  }*/

  a:hover .arrow {
    animation: fadeInOut 1.25s infinite;
  }

}