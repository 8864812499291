/* Grid Settings */
$spacer:					        1em;
$enable-flex:           	true;
$main-sm-columns:			    12;
$sidebar-sm-columns:		  6;
$grid-gutter-width-base:	1.125em;
$container-padding:       1.125em;

/* Colours */
$bg-color:                #FFF;
$brand-primary:				    #3E3D3F;
$text-color:				      #3E3D3F;
$dark-color:				      #3E3D3F;
$light-color:				      #3E3D3F;
//$link-color:				    #b2b2b2;
//$link-hover-color:      #999;
$link-color:				      #3E3D3F;
$link-hover-decoration: 	underline;
$border:					        1px solid #000;
$alt-bg:					        #FFF;

/* Typography */
$font-family-base:        'Sailec-Light', Helvetica, Arial, sans-serif;
$font-family-light:       'Sailec-Light', Helvetica, Arial, sans-serif;
$font-family-regular:     'Sailec-Regular', Helvetica, Arial, sans-serif;
$font-alt:                'Sailec-Light', Helvetica, Arial, sans-serif; /* to delete */
$font-family-monospace:   'Sailec-Light', Helvetica, Arial, sans-serif; /* to delete */
$font-family-sans-serif:	'Sailec-Light', Helvetica, Arial, sans-serif; /* to delete */
$line-height-base:          1.5;

/* Navbar */
/*$navbar-height:					  230px;*/
/*$navbar-default-bg:				  transparent;*/
$navbar-default-link-color:			  $text-color;
$navbar-default-link-hover-color:	  $dark-color;
$navbar-default-link-active-color:    $dark-color;
/*$navbar-padding-vertical:			  20px;
$nav-link-padding:					  20px 28px 10px 0;*/

/*$navbar-default-toggle-border-color:transparent;
$navbar-default-toggle-hover-bg: 	  transparent;
$navbar-default-toggle-icon-bar-bg:   #1f2023;*/

/* MIX INS */

@mixin text-indent {
  text-indent: 			100%;
  white-space: 			nowrap;
  overflow: 			hidden;	  
  font-size: 			1px;
}