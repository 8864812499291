.navbar {
	min-height: 8.5vh;
	margin-left: 5%;
  /*padding: 3.6% 5.55% 0 5.55%;*/
	padding: 2.188em $container-padding;
	/*transition: all 250ms ease-out;*/
	z-index: 1000;
	/*position: absolute;
	width: 95%;*/
}

/*.menu-active .navbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}*/

/*.shrink .navbar {
	padding: 				1.4% 0;
}
*/

.navbar-brand {
	//@include text-indent;
	width: 252px;
	height: 19px;
  color: $brand-primary;
  fill: $brand-primary;
	padding: 0;
	margin: 0;
	text-decoration: none !important;
	border: none !important;
}

.navbar-brand svg {
  max-width: 100%;
  height: auto;
  line-height: 19px;
}

.navbar-brand svg path {
  fill: $brand-primary;
  transition: all 300ms;
}

nav.navbarNav {
	a {
		padding: 0.5em 0 0.5em 2em;
	}
	a.instagram {
		letter-spacing: 1px;
	}
	a.facebook {
		padding-left: 0.75em;
	}
}

nav.fixedNav {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 5%;
	background-color: #fff;
	z-index: 20;
	border-right: $border;
	a {
		position: absolute;
		left: 50%;
		bottom: 1%;
		padding-left: 1.25em;
		margin-left: -0.5em;
		transform: rotate(-90deg);
		transform-origin: top left;
		i {
			position: absolute;
			bottom: 25%;
			left: 0;
		}
	}
}

nav a {
	text-transform: uppercase;
	font-size: 0.813em /*13px*/;
	letter-spacing: 1px;
}

/*
.navbar-toggler {
	background-color: $bg-color;
	margin: 0;
  padding: 10px;
	right: 2.7777%;
	border-radius: 50%;
	border: none;
	width: 42px;
	height: 42px;
	cursor: pointer;
  transition: all 0.4s;
	.menu-active & {
    background-color: transparent;
	}
	.icon-bar {
	  background: $brand-primary;
	  width: 22px;
	  height: 2px;
	  border-radius: 0;
	  display: block;
	  text-align: center;
	  margin: 0 auto;
    transform-origin: center center;
    transition: all 300ms ease-in-out;
	  .menu-active & {
	  	margin-top: 0 !important;
	  	&.one {
        transform: translateY(2px) rotateZ(45deg);
      }
      &.two {
          width: 0 !important;
      }
      &.three {
        transform: translateY(-2px) rotateZ(-45deg);
      }
	  }
	}	
	.icon-bar + .icon-bar {
      margin-top: 4px;
	}
}
*/

/* Menu */

/* Desktop Menu */
/*.nav-primary li {
	padding: 0;
}*/

/* Mobile Menu */
/*#navbarNav {
	background: #fff3e9;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1020;
	transform: translate3d(100%, 0, 0);
	transition: transform 0.4s;

  .container {
    height: 100%;
  }

  .row {
    margin: 0;
  }
}
.menu-active #navbarNav {
	transform: translate3d(0, 0, 0);
}
.navbarNav {
	opacity: 0;
  padding-top: 8.45em;
	padding-bottom: 0;
  transition: opacity 0.75s ease 0.4s;

	a {
    font-family: $font-family-sans-serif;
		font-size: 1.364em;
	  line-height: 1.133;
    padding: 0.45em 0;
	  display: block;
		color: $brand-primary;
	}

	.current_page_item a {
    color: $brand-primary;
	}

	.menu-active & {
    opacity: 1;
	}
}
*/

/* MEDIA QUERIES */

  @media screen and (pointer: fine) and (min-width: 992px) {

    /*.navbar-toggler:hover .icon-bar + .icon-bar {
      margin-top: 6px;
      transition: all 0.3s ease-in-out;
    }
    .menu-active .navbar-toggler:hover .icon-bar + .icon-bar {
      margin-top: 4px;
    }*/

  }

	@include media-breakpoint-up(lg) {

		.navbar {
			padding: 2.188em 2.25em;
		}

    /*#navbarNav {
      box-shadow: -5px 0px 75px -10px rgba(0,0,0,0.06);
      left: 50%;
    }*/
		

	}

	@include media-breakpoint-down(lg) {



	}	

	@include media-breakpoint-up(xl) {

		/*.navbarNav a {
		  font-size: 4em;
		}*/
	}

	/* Mobile (landscape) // bootstrap devices more than 768px ----------- */
	@media (any-hover: hover) {

		/*.navbarNav a:hover {
		  color: $brand-primary;
		}*/

	}

	@include media-breakpoint-up(md) {

		/*.navbar-brand {
		  font-size: 1em;
		}*/

    .navbar-brand {
      width: 252px;
      height: 19px;
    }
		
	}
	@include media-breakpoint-down(md) {

    /*body.menu-active {
      overflow: hidden;
			height: 100vh;
			.navbar-brand {
				display: none;
			}
    }*/

    /*.navbar {
      position: absolute;
    }*/

    /*#navbarNav {
      overflow-y: scroll;
			ul {
				margin-bottom: 0.5em;
			}
			.social {
				font-size: 1.4em;
				a {
					display: inline-block;
				}
			}
    }*/

		.navbarNav {
			padding-top: 4.9em;
		}

		.navbar-brand {
			margin-top: 6px;
			width: 72px;
			height: 28px;
		}
		
	}

		

	/* Mobile // bootstrap devices below 768px ----------- */
	@include media-breakpoint-up(sm) {



	}

	@include media-breakpoint-down(sm) {
  


	}

	@include media-breakpoint-up(lg) {



	}

	@media only screen and (max-height: 1080px) {



	}

	@media only screen and (max-height: 960px) {



	}

		@media screen and (max-width: 320px) {



		}

	@media screen and (max-device-height: 480px) {



	}