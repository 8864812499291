// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

.col-12 > .wp-block-columns,
.col-12 > .wp-block-cover,
.col-12 > .wp-block-image {
  /*margin-bottom: 1.125em;*/
  padding-bottom: 1.125em;
}

/*.wp-block-columns {
  margin-left: auto;
  margin-right: auto;
}*/

/* Columns */
.wp-block-columns {

}
.wp-block-column {
  position: relative;
  margin: 0;
  /*align-self: center;*/
}
.wp-block-column .padding {
  position: relative;
}
.has-1-columns .wp-block-column {
  flex-basis: 100%;
  margin: 0 !important;
}
.has-2-columns .wp-block-column:first-child {
  border-right: $border;
}
.has-2-columns .wp-block-column:last-child {

}
@include media-breakpoint-up(lg) {
  .has-2-columns .wp-block-column {

  }
  .has-2-columns .wp-block-column:first-child {
    border-right: $border;
  }
  .wp-block-column:not(:last-child),
  .wp-block-column:nth-child(odd) {
    padding-right: 1.125em;
    margin-right: 0;
  }
  .wp-block-column:not(:first-child),
  .wp-block-column:nth-child(even) {
    padding-left: 1.125em;
    margin-left: 0;
  }
}
@include media-breakpoint-down(md) {
  .wp-block-column {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
    flex-basis: 100%;
  }
}

/* Blocks */
.wp-block-cover {
  margin-right: -2em;
  min-width: 100%;
  width: auto;
}
.wp-block-cover .wp-block-cover-image-text, .wp-block-cover .wp-block-cover-text, .wp-block-cover h2, .wp-block-cover-image .wp-block-cover-image-text, .wp-block-cover-image .wp-block-cover-text, .wp-block-cover-image h2 {
  font-size: 2.714em;
  max-width: none;
}

.wp-block-spacer {
  height: 1.75em !important;
}

.wp-block-button .wp-block-button__link {
  font-size: 13px;
  font-weight: 300;
  background-color: transparent;
  border: none;
  color: $text-color;
  border-radius: 0;
  padding: 1.125em;
  transition: all 300ms ease;
  &:hover {
    color: #000;
  }
}
.wp-block-button.close {
  position: absolute;
  top: -0.1em;
  right: -0.2em;
  float: right;
  text-shadow: none;
  opacity: 1;
  .padding-border & {
    top: 1em;
  }
}

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
