
.full {
	height: 75vh;
}

.half {
	min-height: 50vh;
}

.page-title.padding {
	padding-top: 1.875em;
	padding-bottom: 1.5em;
}

/* HOME */
.wrap.home .page-title {
	display: none;
}

.wrap.home .wp-block-columns {
	margin-top: 19.5vh;
	.wp-block-column {
		display: flex;
		align-items: center;
	}
	a {
		display: block;
		padding: 2em 3em 2em 0;
	}
}

/* ABOUT */
/*.page-id-807 .navbarNav {
	display: none;
}*/
.wrap.page-id-807 .col-12 > .wp-block-columns > .wp-block-column:last-child {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	.wp-block-columns {
		flex-grow: 2;
	}
	.wp-block-column {

	}
}

/* SERVICES */
.wrap.page-id-805 .services .padding {
	& > * {
		display: none;
	}
	h4 {
		display: block;
	}
}

.wrap.page-template-template-services .grid {
	min-height: 88.812vh;
	article {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.active h3 {
		margin-bottom: 2em;
	}
}

.wrap.page-id-926 .grid article:nth-child(odd),
.wrap.page-id-930 .grid article:nth-child(odd),
.wrap.page-id-928 .grid article:nth-child(even),
.wrap.page-id-931 .grid article:nth-child(even) {
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}






/** MEDIA QUERIES **/

@media screen and (hover: hover), screen and (min-width: 992px) {



}

@media screen and (hover: none), screen and (max-width: 991px) {



}

	/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {



	}

	/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-only(md) {


	}

	@include media-breakpoint-down(md) {



	}

	/* Tablet // bootstrap devices 768px and over ----------- */
	@include media-breakpoint-up(md) {


	}

	/* Laptop // bootstrap large devices 992px and over ----------- */
	@include media-breakpoint-up(lg) {

		.padding {
			padding: 0.694em 1.125em;
		}
		.padding-border .wp-block-column .padding {
			padding-top: 1.75em;
		}


	}	

	@include media-breakpoint-up(xl) {


	}

	@media screen and (max-width: 1600px) {
				
	
	}

	@media screen and (min-width: 1200px) and (max-width: 1700px) {

		
	}

	@media screen and (min-width: 1700px) {
		

	}