/** FONTS */

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/380074");

@font-face {
	font-family: 'Sailec-Light';
	src: url('../fonts/380074_6_0.eot');
	src: url('../fonts/380074_6_0.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/380074_6_0.woff2') format('woff2'),
			 url('../fonts/380074_6_0.woff') format('woff'),
			 url('../fonts/380074_6_0.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Sailec-Regular';
	src: url('../fonts/380074_A_0.eot');
	src: url('../fonts/380074_A_0.eot?#iefix') format('embedded-opentype'),
	url('../fonts/380074_A_0.woff2') format('woff2'),
	url('../fonts/380074_A_0.woff') format('woff'),
	url('../fonts/380074_A_0.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

/** GENERAL LAYOUT */
	*:focus,
	button:focus {
	    outline: none;
	}

	body {
		overflow-x: hidden;
		/*overflow-y: scroll;*/
		font-family: $font-family-base;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing:grayscale;
		font-size: 16px;
		background-color: $bg-color;
		color: $text-color;
	}

	.barba-container {
		position: relative;
	}
		
	h1, h2, h3, h4, h5, h6,
	h1>a, h2>a, h3>a, h4>a, h5>a, h6>a {
		font-family: $font-family-regular;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	h1,
	h2 {
		font-size: 1.375em; /*22px*/
		line-height: 1.182;
		letter-spacing: 1.2px;
	}

	h3,
	h4 {
		font-size: 1.063em; /*17px*/
		line-height: 1.176;
		letter-spacing: 0.93px;
	}

	h4 {
		margin-bottom: 1.182em;
	}

	h5 {
		font-size: 0.875em; /*14px*/
		font-family: $font-family-light;
		text-transform: none;
		padding-bottom: 1.2em;
		strong {
			font-size: 1.214em;
			display: inline-block;
			padding-right: 0.5em;
		}
	}

	h6 {
		font-size: 0.875em; /*14px*/
		letter-spacing: 0.93px;
		padding: 1.5em 0 0.75em 0;
	}

	small,
	.smaller p {
		font-size: 0.875em; /*14px*/
	}

	.larger p {
		font-size: 1.125em; /*18px*/
	}

	blockquote {
		font-size: 				1.125em; /*18px*/
		border: 					none;
		padding: 					0;
		margin: 					0;
	}

	strong, b {
		font-family: 			$font-family-regular;
		font-weight: 			400;
	}
	
	p {
		margin: 					0 0 1em 0;
	}

	ol, ul {
	  padding-left:			0;
	  margin-bottom: 		1.5em;
	}
	li {
	  list-style: 			none;
	  padding: 					0;
	}

	img {
		max-width: 				100%;	
		height: 					auto;
	}

	.img-responsive,
	.grid img {
		width: 100%;
		height: auto;
	}

	.img-padding {
		padding: 13% 16.5%;
	}

	i {
		font-style: normal;
	}
	
	a, a:link, a:visited, a:active {
		color: $text-color;
		text-decoration: none;
		outline: none !important;
		opacity: 1;
		transition: opacity 250ms ease-in-out;
	}

	.alt-bg a {
		color: 					#fff;
		&:hover {
			border-bottom: 		1px solid #fff;
		}
	}
	
	hr {
	  margin-top: 0.5%;
	  margin-bottom: 4.2%;
	  border-top: 1px solid $brand-primary;
		width: 100%;
	}
	
	.accent {
	  color: 			$brand-primary;
	}
	.light {
	  color: 			$light-color;
	}
	.dark {
	  color: 			$dark-color;
	}
	.alt {
	  background-color: $alt-bg;
	}

	.border {
		&.top 		{ border-top: $border; padding-top: 1.125em; }
		&.bottom 	{ border-bottom: $border; margin-bottom: 1.125em; }
		&.left 		{ border-left: $border; }
		&.right 	{ border-right: $border; }
	}
	.padding-border .padding {
		border-top: $border;
	}

	/*.border-top {
	  border-top: 	1px solid #ccc;
	  padding-top:	5.4%;
	}
	.border-left {}
	.border-left:before {
	  content: 			"";
	  position: 		absolute;
	  z-index: 			0;
	  top: 				0;
	  left: 			20px;
	  bottom: 			0;
	  border-left: 		1px solid #ccc;
	}
	.border-right {
		border-right: 	1px solid #ccc;
	}
	.border-bottom {
		border-bottom: 	1px solid #ccc;
		padding-bottom: 3.6144%;
	}*/

	/*.single-projects hr.alignleft {
	    margin-bottom: 8.5% !important;
	}
	.single-projects hr.alignright {
	    margin-bottom: 7% !important;
	}*/
	
	.col-padding {
		padding: 		7.5901%;
	}

	/*.mw-3 p {
		max-width: 			80%;
	}*/
	
	.embed-container { 
		position: 		relative; 
		margin: 		0.25em 0;
		padding-bottom: 56.25%;
		height: 		0;
		overflow: 		hidden;
		max-width: 		100%;
		margin-bottom:	10px;
		transition: 	all 300ms ease;
	}
	.embed-container iframe,
	.embed-container object,
	.embed-container embed { 
		position: 		absolute;
		top: 			0;
		left: 			0;
		width: 			100%;
		height: 		100%;
		cursor: 		pointer;
	}	

	.no-gutter {
		margin-left:	0;
		margin-right:	0;
	}
	.no-gutter > [class*='col-'],
	.no-gutter[class*='col-'] {
		padding-left:	0;
		padding-right:	0;
	}	
	/*.no-gutter-col {
		padding: 		0;
	}*/

	.columns {
	   	font-weight:  	300;
	   	min-height: 	initial;
	}

	.read-more,
	.read-more:active,
	.read-more:visited {
		padding-top: 	2px;
		padding-bottom: 0.2em;
		display: block;
	}

	/*.read-more:hover {
		border: 		none;
		color: 			#000;
	}*/

	.hidden {
		display: 		none !important;
	}

/** ACCORDIAN TOGGLES & TABS **/
	#accordion {
		padding-bottom:	1.5%;
	}
	#accordion article {
		overflow: 		hidden;
		border-bottom: 	1px solid #ccc;
	}
	article.tab {
		position:		relative;
		border-top: 	1px solid #ccc;
	}
	article.tab:first-of-type,
	article.tab:last-of-type {
		border-top: 	none;
	}
	#accordion .tab-content {
		width: 			88%;
		max-width: 		970px;
	}
	#accordion h4 {
		margin: 		0;
	}
	#accordion h4 a {
		display: 		block;
		padding: 		1.1em 0;
	}
	#accordion h4 a:after {
		content: 		"+";
		display: 		inline-block;
		float: 			right;
		padding-right:	3px;
		transition: 	all .35s ease-in-out;
	}
	#accordion h4 a[aria-expanded="true"]:after {
		transform: 		rotate(45deg);
	}	

/** PRELOADER **/
	.preloader {
	    background: 	#fff;
	    position: 		fixed;
	    top: 			0;
	    bottom: 		0;
	    left: 			0;
	    right: 			0;
	    height: 		100%;
	    width: 			100%;
	    z-index: 		1000000;
	}

/** VIDEO **/
	#player, .fallback, video, .video-wrapper video {
		background-color: #FFFFFF !important;
		margin: 		0 !important;
		display: 		block !important;
	}	
	
	video {
		background-size: contain;
		width: 			100%;
		height: 		100%;
	}
		
	video:-webkit-full-page-media,
	video:-moz-full-page-media {
		margin-top: 	0 !important;
		margin-bottom: 	0 !important;
	}

/** ANIMATIONS & TRANSITIONS */
/*
	.rotate {
		-ms-transform: 			rotate(90deg);
    	-webkit-transform: 		rotate(90deg);
    	transform: 				rotate(90deg);
		-ms-transform-origin: 		15% 40%;
		-webkit-transform-origin: 	15% 40%;
		transform-origin: 			15% 40%;
		display: 				inline-block;
	}	
	.do-animation {
	  	transform: translate3d(0,150px,0);
	  	animation: show 1.5s ease forwards;
	  	opacity: 0.1;
	}
	.do-animation:nth-child(odd) {
	  	animation-duration: 1.1s;
	}
	.do-fade {
	  	animation: fade 1.5s ease forwards;
	  	opacity: 0.1;
	}
	.do-zoom {
	  	transform: translate3d(0,100px,0) scale(0.8,0.8);
	  	animation: zoom 0.75s ease forwards;
	  	opacity: 0.1;
	}
	.animate-block:nth-child(even) {
		animation-duration: 1.5s !important; 
	}
	.animate-block:nth-child(odd) {
		animation-duration: 1.1s !important; 
	}
	
	.v3 .animate-block:nth-child(1n),
	.grid .grid-item:nth-child(1n),
	.two-half-cols .animate-block:nth-child(1n) {
		animation-duration: 0.6s !important;
	}
	.v3 .animate-block:nth-child(2n),
	.grid .grid-item:nth-child(2n),
	.two-half-cols .animate-block:nth-child(2n) {
		animation-duration: 1s !important;
	}
	.v3 .animate-block:nth-child(3n),
	.grid .grid-item:nth-child(3n),
	.two-half-cols .animate-block:nth-child(3n) {
		animation-duration: 1.4s !important;
	}
	.already-visible {
	  transform: translate3d(0,0,0) scale(1,1); 
	  animation: none;
	  opacity:1;
	}

	@keyframes show {
	  to { transform: translate3d(0,0,0); opacity:1; }
	}
	@keyframes zoom {
	  to { transform: translate3d(0,0,0) scale(1,1); opacity: 1; }
	}
	@keyframes fade {
	  to { opacity:1; }
	}
*/

/*** MEDIA QUERIES ***/

	/* MIN WIDTH */	

	/* Mobile (portrait) // bootstrap extra small devices less than 576px ----------- */
	@include media-breakpoint-up(xs) {}
	/* No media query since this is the default in Bootstrap*/

	/* Mobile (landscape) // bootstrap small devices 576px and up ----------- */
	@include media-breakpoint-up(sm) {

		/*body {
		  font-size: 16px;
		}*/

	}

	/* Tablet // bootstrap medium devices 768px and up ----------- */
	@include media-breakpoint-up(md) {



		/*body {
		  font-size: 17px;
		  line-height: 1.4em;
		  letter-spacing: -0.1px;
		}

		.cols {
		  columns: 2 350px;
		  
		  p {
		  	padding-right: 4em;
		  }

		}*/

	}

	/* Laptop // bootstrap large devices 992px and up ----------- */
	@include media-breakpoint-up(lg) {



	}

	/* Desktop // bootstrap extra large devices 1200px and up ----------- */
	@include media-breakpoint-up(xl) {



	}

	@media screen and (min-width: 1640px) {



	}

	/* MAX WIDTH */	

	/* Mobile (portrait) // bootstrap extra small devices less than 576px ----------- */
	@include media-breakpoint-down(xs) {}

	/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {}

	/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-down(md) {}

	/* Laptop // bootstrap large devices less than 1200px ----------- */
	@include media-breakpoint-down(lg) {}	

	/* Desktop // bootstrap extra large devices 1200px and up ----------- */
	/* No media query since the extra-large breakpoint has no upper bound on its width */

	/* MIN & MAX WIDTH */

	/* Mobile (portrait) // bootstrap extra small devices (max-width: 575px) ----------- */
	@include media-breakpoint-only(xs) {}
	/* No media query since this is the default in Bootstrap*/

	/* Mobile (landscape) // bootstrap small devices (min-width: 576px) and (max-width: 767px) ----------- */
	@include media-breakpoint-only(sm) {}

	/* Tablet // bootstrap medium devices (min-width: 768px) and (max-width: 991px) ----------- */
	@include media-breakpoint-only(md) {}

	/* Laptop // bootstrap large devices (min-width: 992px) and (max-width: 1199px) ----------- */
	@include media-breakpoint-only(lg) {}

	/* Desktop // bootstrap extra large devices (min-width: 1200px) ----------- */
	@include media-breakpoint-only(xl) {}



	/* Desktop // XX large ----------- */
	@media only screen and (min-width: 1941px) {
		

	}
	
	/* Desktop // X large ----------- */
	@media only screen and (min-width: 1531px) and (max-width: 1940px) {
		

	}
	
	/*@media only screen and (min-width: $screen-lg-min) and (max-width: 1650px) {*/
	@media only screen and (max-width: 1530px) {
		
	}

	@media screen and (max-height: 900px) {
		

	}

	/* Laptop // bootstrap large devices less than 1200px ----------- */
	@include media-breakpoint-down(lg) {	
	

	}	
	
	/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-down(md) {



	}

	@media screen and (pointer: fine) and (min-width: 992px) {

		/** ANIMATIONS & TRANSITIONS */
		.rotate {
			-ms-transform: 			rotate(90deg);
	    	-webkit-transform: 		rotate(90deg);
	    	transform: 				rotate(90deg);
			-ms-transform-origin: 		15% 40%;
			-webkit-transform-origin: 	15% 40%;
			transform-origin: 			15% 40%;
			display: 				inline-block;
		}	
		.do-animation {
		  	/*transform: translate3d(0,150px,0) scale(0.7,0.7);*/
		  	transform: translate3d(0,150px,0);
		  	animation: show 1.5s ease forwards;
		  	opacity: 0.1;
		}
		.do-animation:nth-child(odd) {
		  	animation-duration: 1.1s; /* stagger */
		}
		.do-fade {
		  	animation: fade 1.5s ease forwards;
		  	opacity: 0.1;
		}
		.do-zoom {
		  	transform: translate3d(0,100px,0) scale(0.8,0.8);
		  	animation: zoom 0.75s ease forwards;
		  	opacity: 0.1;
		}
		.animate-block:nth-child(even) {
			animation-duration: 1.5s !important; /* stagger */
		}
		.animate-block:nth-child(odd) {
			animation-duration: 1.1s !important; /* stagger */
		}
		
		.v3 .animate-block:nth-child(1n),
		.grid .grid-item:nth-child(1n),
		.two-half-cols .animate-block:nth-child(1n) {
			animation-duration: 0.6s !important; /* stagger */
		}
		.v3 .animate-block:nth-child(2n),
		.grid .grid-item:nth-child(2n),
		.two-half-cols .animate-block:nth-child(2n) {
			animation-duration: 1s !important; /* stagger */
		}
		.v3 .animate-block:nth-child(3n),
		.grid .grid-item:nth-child(3n),
		.two-half-cols .animate-block:nth-child(3n) {
			animation-duration: 1.4s !important; /* stagger */
		}
		.already-visible {
		  transform: translate3d(0,0,0) scale(1,1); 
		  animation: none;
		  opacity:1;
		}

		@keyframes show {
		  to { transform: translate3d(0,0,0); opacity:1; }
		}
		@keyframes zoom {
		  to { transform: translate3d(0,0,0) scale(1,1); opacity: 1; }
		}
		@keyframes fade {
		  to { opacity:1; }
		}

	}

	@media screen and (pointer: coarse) and (max-width: 991px) {

		/** ANIMATIONS & TRANSITIONS */
		.animate-block {
		  opacity: 0.1;
		}
		.do-animation {
		  opacity: 0.1;
		  animation: fade 1.5s ease forwards;
		}
		.already-visible {
		  opacity: 1 !important;
		}
		@keyframes fade {
		  to { opacity:1; }
		}

	}
	
	/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {	

		/*body {
			font-size: 12px;
		}*/

		.page-loader{
		  display: none !important;
		}

		.animate-block:nth-child(even),
		.v3 .animate-block:nth-child(2n),
		.two-half-cols .animate-block:nth-child(2n),
		.v3 .animate-block:nth-child(3n),
		.two-half-cols .animate-block:nth-child(3n) {
		  animation-duration: 1.1s !important;
		}





	}	

	/* Mobile (portrait) // bootstrap extra small devices less than 576px ----------- */
	@include media-breakpoint-down(xs) {

	}

	@media only screen and (max-height: 618px) { 

	}

	@media only screen and (max-width: 360px) { 

	}