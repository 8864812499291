.page-nav {
  display: none;
  a {
    text-decoration: none;
  }
}

.content-info {
  line-height: 1.364em;
  padding: 3.6em 0 5em;

  .container {
    padding-left: 5.5555%;
    padding-right: 5.5555%;
  }

  &.row,
  .row.no-gutter {
    border-top: 1px solid $brand-primary;
    padding: 4em 0 1em 0;
    /*div:nth-child(2) {
      padding-left: 3em;
    }*/
  }

  h5 {
    text-transform: uppercase;
  }

  p {
    font-size: 11px;
    letter-spacing: -0.2px;
    /*font-size: 0.846em;
    margin: 0;*/
  }

  .fa,
  .social {
    font-size: 1.4em;
    text-decoration: none;
    /*margin-right: 0.74em;*/
  }
}

.home footer.content-info,
.single-post footer.content-info{
  display: none;
}

#navbarNav .content-info.row {
  padding: 2em 0;
  .col-sm-12 {
    padding-bottom: 2.8em;
  }
  /*div:last-child {
    text-align: right;
  }*/
  /*h5 {
    padding-bottom: 0.5em;
  }*/
  .copyright {
    margin-top: 4px;
  }
}


/***MEDIA QUERIES***/

@include media-breakpoint-up(lg) {

  .content-info {
    &.row,
    .row.no-gutter {
      border-top: 1px solid $brand-primary;
      padding: 4em 0 1em 0;
      div:nth-child(2) {
        padding-left: 3em;
      }
    }
  }

  #navbarNav .content-info .col-md-4 p {
    margin-bottom: 4.5em;
  }

  #navbarNav .content-info {
    width: 77.778%;
  }

  .about .page-nav,
  .page-id-76 .page-nav {
    display: block;
  }

  #navbarNav .content-info.row div:last-child {
    text-align: right;
  }

}

/* Tablet // bootstrap devices below 992px ----------- */
@include media-breakpoint-down(md) {

  .content-info {
    padding: 2em 0 1em;
    .col-5 {
      padding-left: 10px;
    }
  }

  /*.content-info > .container > .row:last-child > div:last-child {
    align-self: start;
  }*/

}

/* Mobile // bootstrap devices below 768px ----------- */
@include media-breakpoint-down(sm) {

  .content-info {
    .col-7 {
      padding-right: 0;
    }
    p {
      margin: 0 0 23px;
    }
    h5 br {
      display: none;
    }
  }

  /*
  .content-info {
    p {
      margin-bottom: 0.3em;
    }
    .fa {
      margin-right: 1em;
      margin-top: 1.575em;
    }
    #navbarNav &.flex-column-reverse {
      flex-direction: column !important;
      .fa {
        margin: 1em 0.8em 2.3em 0;
      }
    }
  }
  */
}


/* Laptop // bootstrap devices 992px and over ----------- */
@include media-breakpoint-up(md) {

  footer.content-info .align-self-end {
    text-align: right;
  }

  /*
  .content-info {

    p {
      font-size: 0.8em;
      margin-bottom: 0;
    }

    .fa {
      font-size: 1.2em;
    }
  }

  footer.content-info {
    padding: 4.5% 0 6.25%;

    .fa {
      margin-left: 0.74em;
    }

  }
*/
}

@media screen and (min-height: 600px) {

  #navbarNav .content-info {
    position: absolute;
    bottom: 0;
  }

}

@media screen and (min-height: 600px) and (max-width: 991px) {

  #navbarNav .content-info {
    width: 88.9%;
  }

}

@media screen and (max-width: 320px) {

    .content-info {
      p {
        font-size: 10px;
      }
    }

}

@media screen and (max-device-height: 470px), screen and (max-width: 320px) {


}