/* BARBA */

/** PROJECTS **/
	.grid-item {
		border-top: $border;
		border-bottom: $border;
		padding-top: 1em;
		padding-bottom: 1em;
		margin-bottom: -1px;
		& > .padding {
			border-right: $border;
			display: flex;
			height: 100%;
		}
		/*.wrap.page-template-template-services & > a {
			display: block;
		}*/
	}

	.wrap.single-projects {
		overflow: hidden;
	}

	.entry-content {
		padding-right: 2em;
	}

	.gallery {
		position: relative;
		.padding {
			padding: 2em 10em .694em 1.75em;
			margin: -2.25em 0 0 0;
			position: fixed;
			overflow-y: scroll;
			top: 15.375em;
			bottom: 0;
		}
		img {
			padding-bottom: 1.25em;
		}
		.alignleft {
			margin-right: 0;
		}
		.alignright {
			margin-left: 0;
		}
	}

	.wrap.single-projects .wp-block-button.close {
		right: 1.125em;
	}


/** SINGLE POST **/


		/* POST NAV */
		.posts-navigation {
			font-family: $font-family-sans-serif;
			font-size: 1.308em;
			padding-top: 0.8%;
			padding-bottom: 1.5%;
			p {
				padding-top: 1.5%;
			}
			a {
				text-decoration: none;
			}
		}

		.posts-navigation p:last-child,
		.posts-navigation .nav-previous {
			text-align: right;
			float: right;
		}

		.malinky-ajax-pagination-loading img {
			width: 24px;
			height: 24px;
			display: none;
		}
		.malinky-ajax-pagination-loading,
		.malinky-load-more {
			padding: 2em 0 0;
			text-align: center;
			font-family: $font-family-monospace;
			font-size: 0.824em;
			a {
				color: #C0C0C0;
				transition: color 250ms;
				.modal-open & {
					color: #777;
				}
				&:hover {
					color: $text-color;
				}
			}
		}

		/* MODAL/AJAX */
		.load-article-wrapper {
			background-color: #f1d2c1;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1040;
			overflow: hidden;
			height: 100vh;
			transform: translate3d(100%, 0, 0);
			transition: transform 600ms ease;
			.modal-open & {
				transform: translate3d(0, 0, 0);
			}
			a.close {
				z-index: 1500;
			}
		}


		/* MEDIA QUERIES */

		@media screen and (min-width: 1500px) {



		}

		@media screen and (min-width: 1800px) {



		}

		/* Desktop // bootstrap extra large devices 1200px and up ----------- */
		@include media-breakpoint-up(xl) {




		}

		/* Laptop // bootstrap large devices less than 1200px ----------- */
		@include media-breakpoint-down(lg) {




		}

		/* Laptop // bootstrap large devices 992px or more ----------- */
		@include media-breakpoint-up(lg) {

			.wrap.single-projects .grid-item:nth-child(3n+3) > a {
				border-right: none;
			}

		}

		/* Tablet // bootstrap medium devices less than 992px ----------- */
		@include media-breakpoint-down(md) {



		}

		/* Tablet // bootstrap medium devices 768px and up ----------- */
		@include media-breakpoint-up(md) {

			.wrap.page-template-template-services .grid-item:nth-child(even) .padding,
			.wrap.page-id-805 .grid-item:nth-child(even) .padding {
				border-right: none;
			}

		}

		/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
		@include media-breakpoint-down(sm) {

			.portrait {
				padding-right: 10px;
			}
			.portrait+.portrait {
				padding: 0 20px 0 10px;
			}

			.posts-navigation > div {
				padding: 0 12px;
			}
			.view {
				margin: 8% auto 7.5% auto;
				text-align: center;
			}

	}

	@media (hover: hover) {



	}
